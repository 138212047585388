import { DashboardData } from '../components/MetricsContainer'
import { AttributionTableTabs, DashboardMachineData, DashboardMachineState } from '../machines/dashboardMachine/dashboardMachine'

// This logic used to help filter the aggregates based on what was selected on the same tab. This might be useful in the future when we have multiple platforms. Talk to Ramley...
// export const aggregateSelectedItems = (data: any[], selectedRowKeys: RowKey[]) => {
//   return data
//     .filter((row) => selectedRowKeys.includes(row.id))
//     .reduce(
//       (acc, item) => ({
//         spend: (acc.spend ?? 0) + (item.spend ?? 0),
//         orders: (acc.orders ?? 0) + (item.orders ?? 0),
//         sourceOrders: (acc.sourceOrders ?? 0) + (item.sourceOrders ?? 0),
//         ordersValue: (acc.ordersValue ?? 0) + (item.ordersValue ?? 0),
//         sourceOrdersValue: (acc.sourceOrdersValue ?? 0) + (item.sourceOrdersValue ?? 0),
//       }),
//       {} as { [key: string]: number },
//     )
// }

// export const dataSelector = (state: DashboardMachineState) => {
//   const { selectedCampaigns, selectedAdsets, selectedAds, data } = state.context

//   switch (state.context.tab) {
//     case AttributionTableTabs.CAMPAIGNS:
//       return selectedCampaigns.length > 0 ? aggregateSelectedItems(data.campaignsQuery?.campaigns ?? [], selectedCampaigns) : data.campaignsQuery?.aggregates
//     case AttributionTableTabs.ADSETS:
//       return selectedAdsets.length > 0 ? aggregateSelectedItems(data.adsetsQuery?.adsets ?? [], selectedAdsets) : data.adsetsQuery?.aggregates
//     case AttributionTableTabs.ADS:
//       return selectedAds.length > 0 ? aggregateSelectedItems(data.adsQuery?.ads ?? [], selectedAds) : data.adsQuery?.aggregates
//   }
// }

export const tableSummaryMetricsSelector = (state: DashboardMachineState) => {
  const { data } = state.context

  switch (state.context.tab) {
    case AttributionTableTabs.CAMPAIGNS:
      return tableSummaryMetricsMapper(data.campaignsQuery)
    case AttributionTableTabs.ADSETS:
      return tableSummaryMetricsMapper(data.adsetsQuery)
    case AttributionTableTabs.ADS:
      return tableSummaryMetricsMapper(data.adsQuery)
  }
}

export const tableSummaryMetricsMapper = (data: DashboardMachineData['campaignsQuery'] | DashboardMachineData['adsetsQuery'] | DashboardMachineData['adsQuery']): DashboardData => {
  return {
    spend: data?.aggregates.spend ?? 0,
    facebookOrders: data?.aggregates.sourceOrders ?? 0,
    facebookRevenue: data?.aggregates.sourceOrdersValue ?? 0,
    attributedOrders: data?.aggregates.orders ?? 0,
    attributedRevenue: data?.aggregates.ordersValue ?? 0,
  }
}

export const cardsMetricsSelector = (state: DashboardMachineState): DashboardData => {
  const { data } = state.context
  return {
    dailyBudget: data.campaignsQuery?.totals.dailyBudget ?? 0,
    orders: data.campaignsQuery?.totals.orders.total ?? 0,
    revenue: data.campaignsQuery?.totals.revenue.total ?? 0,
    spend: data.campaignsQuery?.totals.spend ?? 0,
    facebookOrders: data.campaignsQuery?.totals.orders.facebook ?? 0,
    facebookRevenue: data.campaignsQuery?.totals.revenue.facebook ?? 0,
    attributedOrders: data.campaignsQuery?.totals.orders.attributed ?? 0,
    attributedRevenue: data.campaignsQuery?.totals.revenue.attributed ?? 0,
    organicOrders: data.campaignsQuery?.totals.orders.organic ?? 0,
    organicRevenue: data.campaignsQuery?.totals.revenue.organic ?? 0,
  }
}

import React, { useContext } from 'react'
import { Table, Space, Typography } from 'antd'
import { colorTextTertiary } from '../../theme/colors'
import { formatNumber } from '../../common/utils/formatter'
import styled from 'styled-components'
import { AttributionTableTabs } from '../../machines/dashboardMachine/dashboardMachine'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { useSelector } from '@xstate/react'
import { tableSummaryMetricsSelector } from '../../utils/dashboard-util'
import { pluralize } from '../../utils/pluralize'
import { DashboardData } from '../../components/MetricsContainer'
import { CustomColumnType } from '../AttributionTables/columns'

const { Text: _Text } = Typography
const { Summary } = Table

const SummarySpace = styled(Space)`
  gap: 0px !important;
`
const Cell = styled(Summary.Cell)<{ footerbackground: string }>`
  background: ${(props) => props.footerbackground} !important;
`

const FixedCell = styled(Cell)`
  z-index: 20 !important;
`

const SummaryText = styled(_Text)`
  font-size: 14px;
  font-weight: 600;
`

const SummaryMetric = styled(_Text)`
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SummaryLabel = styled(_Text)`
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TableSummary = (columns: CustomColumnType[]) => {
  const globalServices = useContext(GlobalStateContext)
  const totals: DashboardData = useSelector(globalServices.dashboardService, tableSummaryMetricsSelector)

  let currentTab = AttributionTableTabs.CAMPAIGNS
  let tabName = 'Campaign'
  const totalCount = useSelector(globalServices.dashboardService, (state) => {
    currentTab = state.context.tab
    switch (currentTab) {
      case AttributionTableTabs.CAMPAIGNS:
        tabName = 'Campaign'
        return state.context.data.campaignsQuery?.total ?? 0
      case AttributionTableTabs.ADSETS:
        tabName = 'Adset'
        return state.context.data.adsetsQuery?.total ?? 0
      case AttributionTableTabs.ADS:
        tabName = 'Ad'
        return state.context.data.adsQuery?.total ?? 0
      default:
        return 0
    }
  })

  const formattedTotals = {
    dailyBudget: `$${formatNumber(totals?.dailyBudget ?? 0, 2)}`,
    spend: `$${formatNumber(totals?.spend ?? 0, 2)}`,
    attributedOrders: formatNumber(totals?.attributedOrders ?? 0, 0),
    attributedRevenue: `$${formatNumber(totals?.attributedRevenue ?? 0, 2)}`,
    facebookOrders: formatNumber(totals?.facebookOrders ?? 0, 0),
    facebookRevenue: `$${formatNumber(totals?.facebookRevenue ?? 0, 2)}`,
  }

  const count = Math.floor(totalCount)
  const summaryTitle = `Results from ${count.toLocaleString()} ${pluralize(tabName, count)}`

  return (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <FixedCell footerbackground={'#FCFCFC'} align="center" colSpan={2} index={0}>
          <SummaryText>{summaryTitle}</SummaryText>
        </FixedCell>
        <Cell footerbackground={'#FCFCFC'} align="center" index={2}></Cell>
        {columns.slice(2).map((column, index) => {
          const key = column?.totalsKey as keyof typeof formattedTotals
          const shouldHideTotal = column?.shouldHideTotal
          if (shouldHideTotal) {
            return <Cell key={key} footerbackground={'#FCFCFC'} align="center" index={index + 2}></Cell>
          }
          return (
            <Cell key={key} footerbackground={'#FCFCFC'} align="center" index={index + 2}>
              <SummarySpace direction="vertical">
                <SummaryMetric>{key in formattedTotals ? formattedTotals[key] : '-'}</SummaryMetric>
                <SummaryLabel style={{ color: colorTextTertiary }}>{`Total ${column.title}`}</SummaryLabel>
              </SummarySpace>
            </Cell>
          )
        })}
      </Table.Summary.Row>
    </Table.Summary>
  )
}

import { Radio, Select, Space, Typography } from 'antd'
import { FormItem } from '../StyledComponents'
import { useState } from 'react'
import { filterTimezones } from './filterTimezones'

export const TimezoneSelect = ({ timezone }: { timezone?: string | null }) => {
  const [timezoneType, setTimezoneType] = useState(timezone ? 'static' : 'dynamic')
  const [search, setSearch] = useState('')
  const searchTimezones = filterTimezones()

  return (
    <FormItem label="Timezone" name="timezoneType" rules={[{ required: timezone ? false : true, message: 'Please select your timezone type.' }]}>
      <Radio.Group style={{ width: '100%' }} onChange={(e) => setTimezoneType(e.target.value)} value={timezoneType}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Radio value="static">Static</Radio>
          <FormItem
            name="timezone"
            rules={[
              {
                required: timezoneType === 'static',
                message: 'Please select a timezone.',
              },
            ]}
          >
            <Select
              style={{ paddingLeft: 32 }}
              onSearch={(value) => setSearch(value)}
              disabled={timezoneType !== 'static'}
              size="large"
              placeholder="Select timezone"
              showSearch
              filterOption={false}
              options={searchTimezones(search).map((tz) => ({
                label: `${tz.countryName} (${tz.abbreviation}) (UTC ${tz.offsetInHours})`,
                value: tz.name,
              }))}
            />
          </FormItem>

          <Radio value="dynamic">Dynamic</Radio>
          <div>
            <Typography.Text type="warning">Select carefully, tracking data will be relative to the timezone selected.</Typography.Text>
          </div>
        </Space>
      </Radio.Group>
    </FormItem>
  )
}

import { useState, useRef, useContext, useMemo, useEffect } from 'react'
import { Space, Divider as _Divider, Card as _Card } from 'antd'
import DatePicker from './DatePicker'
import { Button } from '../StyledComponents'
import styled from 'styled-components'
import { useSelector } from '@xstate/react'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { DashboardMachineState } from '../../machines/dashboardMachine/dashboardMachine'
import { useDayjs, Dayjs } from '../../hooks/useDayjsHook'

const { RangePicker: _RangePicker } = DatePicker

enum Status {
  IDLE = '',
  WARNING = 'warning',
  ERROR = 'error',
}

type SelectedDates = [Dayjs | null, Dayjs | null] | null
type ConfirmedDates = [Dayjs, Dayjs] | null

const RangePicker = styled(_RangePicker)`
  border-radius: 12px;
`

const Card = styled(_Card)`
  & .ant-card-body {
    padding: 0;
  }
`

const ActionButtonSpace = styled(Space)`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Divider = styled(_Divider)`
  margin: 0;
`

const dateRangeSelector = (dayjs: any, state: DashboardMachineState) => {
  const { start, end } = state.context.dateRange ?? [null, null]
  return [start, end]
}

const rangePresets = (dayjs: any): {
  label: string
  value: [Dayjs, Dayjs]
}[] => {
  const today = dayjs()
  return [
    { label: 'Today', value: [today, today] },
    { label: 'Last 7 Days', value: [today.subtract(7, 'd'), today] },
    { label: 'Last 14 Days', value: [today.subtract(14, 'd'), today] },
    { label: 'Last 30 Days', value: [today.subtract(30, 'd'), today] },
    { label: 'Last 90 Days', value: [today.subtract(90, 'd'), today] },
    {label: "Maximum", value: [today.subtract(3, 'y'), today]}
  ]
}

export const DashboardRangePicker = () => {
  const { dayjs, timezone } = useDayjs()

  const memoizedRangePresets = useMemo(() => rangePresets(dayjs), [timezone])

  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService
  const confirmedDates = useSelector(globalServices.dashboardService, (state) => dateRangeSelector(dayjs, state))

  const setDateRange = (dateRange: ConfirmedDates) => {
    const [startDate, endDate] = dateRange ?? [null, null]
    const start = startDate ? startDate.format() : null
    const end = endDate ? endDate.format() : null
    send({ type: 'SET_DATE_RANGE_EVENT', dateRange: { start, end } })
  }

  const [status, setStatus] = useState<Status>(Status.IDLE)

  const getPersistedDates = (): SelectedDates => {
    return [confirmedDates?.[0] ? dayjs(confirmedDates[0]) : null, confirmedDates?.[1] ? dayjs(confirmedDates[1]) : null]
  }

  const persistedDates: SelectedDates = getPersistedDates()
  const [selectedDates, setSelectedDates] = useState<SelectedDates>(persistedDates)
  const [pickerVisible, setPickerVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!confirmedDates?.[0] && !confirmedDates?.[1]) {
      (async () => {
        await new Promise(r => setTimeout(r, 200))
        const today = dayjs()
        const defaultDates: ConfirmedDates = [today.startOf('day'), today.endOf('day')]
        setDateRange(defaultDates)
        setSelectedDates(defaultDates)
      })()
    }
  }, []) // Empty dependency array means this only runs once on mount

  const pickerRef = useRef<HTMLDivElement | null>(null)
  const pickerModalRef = useRef<HTMLDivElement | null>(null)

  const handlePickerVisibleChange = (visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        if (
          (pickerRef.current && pickerRef.current.contains(document.activeElement)) ||
          (pickerModalRef.current && pickerModalRef.current.contains(document.activeElement)) ||
          (document.activeElement && document.activeElement.classList.contains('ant-picker-panel-container'))
        ) {
          return
        }
        setPickerVisible(false)
        setStatus(Status.IDLE)
        setSelectedDates(persistedDates)
      }, 0)
    } else {
      setPickerVisible(true)
    }
  }

  const handleClear = () => {
    const start = dayjs().startOf('day')
    const end = dayjs().endOf('day')
    setSelectedDates([start, end])
    setDateRange([start, end])
    setStatus(Status.IDLE)
  }

  const handleApply = () => {
    if (selectedDates?.[0] && selectedDates?.[1]) {
      const [start, end] = selectedDates
      const updatedDates: SelectedDates = [start.startOf('day'), end.endOf('day')]
      setPickerVisible(false)
      setDateRange(updatedDates as ConfirmedDates)
      setStatus(Status.IDLE)
    } else {
      setStatus(Status.ERROR)
    }
  }

  const handleChange = (dates: SelectedDates) => {
    if (dates) {
      setSelectedDates(dates)
      setStatus(Status.IDLE)
    } else {
      setDateRange(null)
    }
  }

  return (
    <div ref={pickerRef}>
      <RangePicker
      
        variant="outlined"
        size="large"
        value={selectedDates}
        open={pickerVisible}
        status={status}
        onChange={handleChange}
        onOpenChange={handlePickerVisibleChange}
        onCalendarChange={handleChange}
        presets={memoizedRangePresets}
        panelRender={(panelNode: any) => {
          return (
            <Card ref={pickerModalRef}>
              <Space direction="horizontal">{panelNode}</Space>
              <Divider />
              <ActionButtonSpace>
                <Button onClick={handleClear} size="large" type="default">
                  Clear
                </Button>
                <Button onClick={handleApply} size="large" type="primary">
                  Apply
                </Button>
              </ActionButtonSpace>
            </Card>
          )
        }}
      />
    </div>
  )
}

import { useSelector } from '@xstate/react'
import { AttributionTableTabs, DashboardMachineState, RowKey } from '../../machines/dashboardMachine/dashboardMachine'
import { AttributionTable } from '../AttributionTable'
import { SortInput } from '../../__generated__/graphql'
import { useContext } from 'react'
import { getColumns } from './columns'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'

const selectedCampaignsSelector = (state: DashboardMachineState) => state.context.selectedCampaigns
const limitSelector = (state: DashboardMachineState) => state.context.limit
const offsetSelector = (state: DashboardMachineState) => state.context.offset.campaigns
const sortSelector = (state: DashboardMachineState) => state.context.sort.campaigns
const dataSelector = (state: DashboardMachineState) => state.context.data.campaignsQuery

export const CampaignsTable = () => {
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService

  const selectedCampaigns = useSelector(globalServices.dashboardService, selectedCampaignsSelector)
  const limit = useSelector(globalServices.dashboardService, limitSelector)
  const offset = useSelector(globalServices.dashboardService, offsetSelector)
  const sort = useSelector(globalServices.dashboardService, sortSelector)
  const data = useSelector(globalServices.dashboardService, dataSelector)

  const setSelectedCampaigns = (selectedCampaigns: RowKey[]) => send({ type: 'SET_SELECTED_CAMPAIGNS_EVENT', selectedCampaigns })
  const setOffset = (offset: number) => send({ type: 'SET_CAMPAIGNS_TABLE_OFFSET_EVENT', offset })
  const setLimit = (limit: number) => send({ type: 'SET_LIMIT_EVENT', limit })
  const setSort = (sort: SortInput[]) => send({ type: 'SET_CAMPAIGNS_TABLE_SORT_EVENT', sort })
  const handleCampaignClick = (key: RowKey) => send({ type: 'CAMPAIGN_CLICK_EVENT', selectedCampaigns: [key], tab: AttributionTableTabs.ADSETS })

  return (
    <AttributionTable
      title="Campaigns"
      sort={sort}
      columns={getColumns(true, handleCampaignClick)}
      // loading={loading}
      // TODO: Get Rid of mapping here by backend returning correct values and keys
      data={(data?.campaigns || []).map(({ id, name, status, spend, dailyBudget, orders, ordersValue, sourceOrders, sourceOrdersValue, sourceCampaignId }) => {
        return {
          key: id,
          name,
          status,
          spend: spend ?? undefined,
          budget: dailyBudget ?? 0,
          on: true,
          orders: orders,
          ordersValue: ordersValue,
          sourceOrders: sourceOrders,
          sourceOrdersValue: sourceOrdersValue,
          id: sourceCampaignId,
        }
      })}
      limit={limit}
      total={data?.total}
      selectedRowKeys={selectedCampaigns}
      setLimit={setLimit}
      offset={offset}
      setSort={setSort}
      setOffset={setOffset}
      setSelectedRowKeys={setSelectedCampaigns}
    />
  )
}

import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Dropdown, MenuProps } from 'antd'
import styled from 'styled-components'
import { colorTextSecondary } from '../../../theme/colors'
import { Link, useNavigate } from 'react-router-dom'
import { Routes } from '../../../Routes'
import { useLogoutMutation } from '../../../__generated__/graphql'
import { useContext, useEffect, useState } from 'react'
import { JwtContext } from '../../../contexts/jwtContext'
import { client } from '../../../graphql/client'
import { clearPrivateStorageLogout } from '../../../services/auth'

const MenuButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  padding-left: 0;
  color: ${colorTextSecondary};
`

const getMenuItems = (hideProfile: boolean): MenuProps['items'] => [
  ...(hideProfile ? [] : [{
    key: 'profile',
    icon: <UserOutlined />,
    label: <Link to={Routes.PROFILE_SETTINGS}>Profile</Link>,
  }]),
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
    danger: true,
  },
]

const logoutActions = () => {
  clearPrivateStorageLogout()
  client.resetStore()
}

interface ProfileActionProps {
  hideProfile?: boolean;
}

export const ProfileAction = ({ hideProfile = false }: ProfileActionProps) => {
  const navigate = useNavigate()
  const decodedToken = useContext(JwtContext)
  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')

  useEffect(() => {
    if (decodedToken?.user === null) {
      return
    }
    setFirst(decodedToken!.user!.firstName)
    setLast(decodedToken!.user!.lastName)
  }, [decodedToken])

  const [logoutMutation] = useLogoutMutation({
    onCompleted: logoutActions,
    onError: logoutActions,
  })

  const onClick: MenuProps['onClick'] = async ({ key }) => {
    switch (key) {
      case 'logout':
        await logoutMutation()
        navigate(Routes.LOGIN)
        break
    }
  }

  const items = getMenuItems(hideProfile)

  return (
    <Dropdown menu={{ items, onClick }} placement="bottomLeft">
      <Avatar size="large">{`${first?.charAt(0).toUpperCase()}${last?.charAt(0).toUpperCase()}`}</Avatar>
    </Dropdown>
  )
}

import { CheckOutlined } from '@ant-design/icons'
import { theme, Card, Space, Divider, Row, Col, Button, Typography } from 'antd'
const { Text } = Typography
interface SubscriptionCardProps {
  link?: string
  name: string
  description: string
  price: string
  oldPrice: string
  recommended?: boolean
  features: string[]
  onChooseSubscription: (link:string | undefined) => void
  yearlySwtich?: React.Dispatch<React.SetStateAction<boolean>>
}
export const SubscriptionCard = ({ link, name, description, price, oldPrice, features, recommended, onChooseSubscription, isYearly }: SubscriptionCardProps & { isYearly?: boolean }) => {
  const {
    token: { colorSuccess, colorPrimary, colorErrorActive, colorPrimaryBg, colorTextTertiary, colorBorderSecondary },
  } = theme.useToken()
  return (
    <Card
      style={{ border: `${recommended ? `solid 3px ${colorPrimary}` : `solid 2px ${colorBorderSecondary}`}`, borderRadius: 20, maxWidth: 380, height: 'fit-content' }}
      styles={{ header: { background: `${colorPrimaryBg}`, borderBottom: `solid 3px ${colorPrimary}`, borderTopLeftRadius: '17px', borderTopRightRadius: '17px', color: colorPrimary } }}
    >
      <Space direction="vertical" style={{ width: '100%', textAlign: 'left', padding: 24 }} size={'small'}>
        <Text style={{ fontSize: 24, fontWeight: 600 }}>{name}</Text>
        <Text style={{ fontSize: 14, fontWeight: 500 }}>{description}</Text>
        <Divider style={{ marginBottom: 12 }} />
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Text style={{ fontSize: 32, fontWeight: 600 }}>${price}</Text> <Text style={{ fontSize: 14, fontWeight: 500, marginLeft: 4, color: colorTextTertiary }}>{isYearly ? '/yr' : '/mo'}</Text>
          </Col>
          <Col>
            <Text delete style={{ fontSize: 40, fontWeight: 500, color: colorErrorActive }}>
              ${oldPrice}
            </Text>
            <Text style={{ fontSize: 14, fontWeight: 500, marginLeft: 4, color: colorTextTertiary }}>{isYearly ? '/yr' : '/mo'}</Text>
          </Col>
        </Row>
        <Divider style={{ marginTop: 12 }} />
        <Row style={{ minHeight: 140 }}>
          <Col span={12}>
            {features.slice(0, Math.ceil(features.length/2)).map((feature, index) => (
              <Row key={index} style={{marginBottom: 8}}>
                <CheckOutlined style={{ color: colorSuccess, marginRight: 8 }} />
                <Text style={{ fontSize: 14, fontWeight: 600 }}>{feature}</Text>
              </Row>
            ))}
          </Col>
          <Col span={12}>
            {features.slice(Math.ceil(features.length/2)).map((feature, index) => (
              <Row key={index} style={{marginBottom: 8, alignItems: 'center', flexWrap: 'nowrap', display: 'flex' }} justify="start">
                  <CheckOutlined style={{ color: colorSuccess, marginRight: 8 }} />
                  <Text style={{ fontSize: 14, fontWeight: 600 }}>{feature}</Text>
              </Row>
            ))}
          </Col>
        </Row>
        {
          <Button onClick={()=>{
            onChooseSubscription(link)
          }} type={'primary'} style={{ marginTop: 16 , minHeight: 40}} block>
            Choose Plan
          </Button>
        }

        {price !== '0' && (
          <div style={{ textAlign: 'center' }}>
            <Text style={{ fontWeight: 500, fontSize: 12, color: colorPrimary }}>Save With Annual Billing (20% Off)</Text>
          </div>
        )}
                {
          <Text style={{ textAlign: 'center', fontSize: 14, fontWeight: 500, color: 'black', display: 'block', marginTop: 8 }}>If it doesn't track, you don't pay!</Text>
        }
      </Space>
    </Card>
  )
}

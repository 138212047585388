import { useContext, useState, useEffect } from 'react'
import { JwtContext } from '../../../contexts/jwtContext'
import { SubscriptionCarosel } from './SubscriptionCarosel'
import { SubscriptionPlan, SubscriptionPlanStatus, useCreateBillingPortalByStoreIdMutation, useSubscriptionPlanByStoreIdQuery } from '../../../__generated__/graphql'
import { Card, Descriptions, List } from 'antd'
import { Link, useSearchParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { DecodedToken } from '../../../providers/jwtProvider'
import dayjs from '../../../services/dayjs'
import styled from 'styled-components'
import { Routes } from '../../../Routes'

const StyledCard = styled(Card)<{ standAlone?: boolean }>`
  margin: ${props => props.standAlone ? '40px' : '0px'};
  border: ${props => props.standAlone ? 'default' : 'none'};
`;


function getPlanName(isYearly: boolean) : string {
  if(isYearly) {
    return 'Standard (Yearly)'
  } else {
    return 'Standard (Monthly)'
  }
}

interface SubscriptionPageProps {
  onChooseSubscription: (link:string | undefined) => void;
  standAlone?: boolean;
}

export const SubscriptionPage =  ({ onChooseSubscription, standAlone = false }: SubscriptionPageProps)   => {

  const decodedToken : DecodedToken = useContext(JwtContext)
  const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan | undefined>()
  const [portalURL, setPortalURL] = useState('')
  const [subscribed, setSubscribed] = useState(false)
  const [searchParams] = useSearchParams()
  const [isModalOpen, setIsModalVisible] = useState(searchParams.get('success') === 'true')

  useEffect(() => {
    if (searchParams.get('success') === 'true') {
      setIsModalVisible(true)
    }
  }, [searchParams])

  const handleOk = () => {
    setIsModalVisible(false)
  }

  if (!decodedToken || !decodedToken.stores || decodedToken.stores.length === 0) {
    return (
      <>
        <Descriptions title="You must first connect a store before you can subscribe." column={1}/>
      </>
    )
  } else {


    const { loading } = useSubscriptionPlanByStoreIdQuery({
      variables: {
        storeId: decodedToken.stores[0],
      },
      onError: (error) => {
        console.log(error)
      },
      onCompleted: (data) => {
        if (data && data.subscriptionPlanByStoreId?.__typename === 'SubscriptionPlan' && (data.subscriptionPlanByStoreId.status === SubscriptionPlanStatus.Active || data.subscriptionPlanByStoreId.status === SubscriptionPlanStatus.PastDue || data.subscriptionPlanByStoreId.status === SubscriptionPlanStatus.Trialing)) {
          setSubscriptionPlan(data.subscriptionPlanByStoreId as SubscriptionPlan)
          setSubscribed(true)
          try {
            getBillingPortalURL()
          } catch (error) {
            console.log(error)
          }
          
        } else {
          setSubscribed(false)
        }
      },
    })

    const [getBillingPortalURL] = useCreateBillingPortalByStoreIdMutation({
      variables: {
        id: decodedToken.stores[0],
        input: {
          returnUrl: Routes.SUBSCRIPTION_SETTINGS,
        },
      },
      fetchPolicy: 'no-cache',
      onError: (error) => {
        console.log(error)
      },
      onCompleted: (data) => {
        if (data && data.createBillingPortalByStoreId?.__typename === 'BillingPortal') {
          setPortalURL(data.createBillingPortalByStoreId.sessionUrl)
        }
      },
    })

    return (
      <>
        <StyledCard style={{ margin: standAlone ? '40px' : '0px' }}>
          {subscribed ? (
            <List>
              <List.Item>
                <Descriptions title="Subscription Details" column={1}>
                  <Descriptions.Item label="Subscription Status">{subscriptionPlan && subscriptionPlan.status.toUpperCase()}</Descriptions.Item>
                  <Descriptions.Item label="Subscription Plan">{subscriptionPlan && getPlanName(subscriptionPlan.isYearly ?? true)}</Descriptions.Item>
                  <Descriptions.Item label={subscriptionPlan?.canceledAt ? 'Expiration Date' : 'Renewal Date'}>
                    {subscriptionPlan && dayjs(subscriptionPlan.currentPeriodEnd.toString()).format('MM/DD/YYYY')}
                  </Descriptions.Item>
                </Descriptions>
              </List.Item>
              <List.Item>
                <Link to={portalURL}>Manage Subscription</Link>
              </List.Item>
            </List>
          ) : loading ? (
            <LoadingOutlined />
          ) : (
            <SubscriptionCarosel onSubscriptionSelected={onChooseSubscription} successUrl={Routes.SUBSCRIPTION_SETTINGS + '?success=true'} failUrl={Routes.SUBSCRIPTION_SETTINGS} />
          )}
        </StyledCard>
      </>
    )
  }
}

export default SubscriptionPage


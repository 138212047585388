import { ProfileAction } from '../../components/HeaderActions/Profile/Profile'
import { SubscriptionPage } from '../QuickStart/Subscription'
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout'

export const SubscriptionSettings = () => {
  return (
    <ResponsiveSiderLayout title="My Subscription" primaryPageKey="subscription" actions={<ProfileAction key={1} />} mobileActions={[<ProfileAction key={4} />]}>
      <SubscriptionPage standAlone={true} onChooseSubscription={(url) => {
        if (!url) return
        window.open(url, '_blank')
      }} />
    </ResponsiveSiderLayout>
  )
}

import { Row, Form, Typography, message, Col, theme, Select } from 'antd'
import { Button } from '../../components/StyledComponents'
import { Input } from '../../components/StyledComponents'
import styled from 'styled-components'
import { FacebookAttribution, FoxTraxAttribution, useUpdateOrganizationByIdMutation, useUpdateUserByIdMutation, useViewerQuery } from '../../__generated__/graphql'
import { Spin } from '../../components/StyledComponents'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../Routes'
import { LocalStorageKeys } from '../../common/types/localStorage'
import { PhoneInput } from '../StyledComponents/PhoneInput'
import { useContext, useEffect, useState } from 'react'
import { TimezoneSelect } from '../TimezoneSelect/TimezoneSelect'
import { localStorageSetItem } from '../../services/storage'
import { DashboardMachineState } from 'src/machines/dashboardMachine/dashboardMachine'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { useSelector } from '@xstate/react'
import { useDayjs } from '../../hooks/useDayjsHook'

const { Text } = Typography

const { Option } = Select

/* eslint-enable no-template-curly-in-string */

const TitleText = styled(Text)`
  color: var(--neutral-07, #1a1d1f);

  /* Title 1 (Semibold) */
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.4px;
`

const FieldLabelText = styled(Text)`
  color: #959da1;
  font-size: 12px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.24px;
`

const InputTextField = styled(Input)`
  display: flex;
  height: 62px;
  padding: 27px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 15px;
  border: 1px solid var(--secondary-200, #dce4e8);
  background: var(--primary-0, #fff);
`

const UnderlinedText = styled(Text)`
  color: var(--neutral-07, #1a1d1f);
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.15px;
  text-decoration-line: underline;
`

const AttributionSelectField = styled(Select)`
  height: 62px !important;
  .ant-select-selector {
    border-radius: 15px !important;
  }
`

const attributionSettingSelector = (state: DashboardMachineState) => state.context.attributionSetting
const dateRangeSelector = (state: DashboardMachineState) => state.context.dateRange

export const ProfileSettingsForm = () => {
  const { dayjs, timezone: dayjsTimezone } = useDayjs()
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService
  const attributionSetting = useSelector(globalServices.dashboardService, attributionSettingSelector)
  const dateRange = useSelector(globalServices.dashboardService, dateRangeSelector)

  const setAttributionSetting = (attributionSetting: { foxtraxAttribution?: FoxTraxAttribution; facebookAttribution?: FacebookAttribution }) =>
    send({ type: 'SET_ATTRIBUTION_SETTINGS_EVENT', attributionSetting })


  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [phoneInputError, setPhoneInputError] = useState(false)
  const {
    token: { colorBorder, colorError },
  } = theme.useToken()
  const [countryCode, setCountryCode] = useState('1')

  const viewerResult = useViewerQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setCountryCode(data.viewer?.countryCode ?? '1')
    },
    onError: (error) => {
      console.log(error)
      message.error('Something went wrong')
    },
  })

  const [updateUserMutation, { loading: updateUserLoading }] = useUpdateUserByIdMutation({
    onCompleted: (data) => {
      console.log(data)
      console.log('User updated successfully')
      message.success('User updated successfully')
    },
    onError: (error) => {
      console.log(error)
      console.log('Something went wrong updating user')
      message.error('Something went wrong')
    },
  })

  const [updateOrgMutation] = useUpdateOrganizationByIdMutation({
    onCompleted: (data) => {
      console.log(data)
      console.log('Organization updated successfully')
    },
    onError: (error) => {
      console.log(error)
      console.log('Something went wrong updating organization')
      message.error('Something went wrong')
    },
  })

  useEffect(() => {
    const shouldReset = (dateRange.start && dateRange.start !== dayjs(dateRange.start).format()) || 
      (dateRange.end && dateRange.end !== dayjs(dateRange.end).format())

    console.log('ProfileSettingsForm_shouldReset???????????????????', shouldReset)
    if (shouldReset) {
      const today = dayjs()
      const start = today.startOf('day').format()
      const end = today.endOf('day').format()
      send({ type: 'SET_DATE_RANGE_EVENT', dateRange: { start, end} })
    }
  }, [dateRange, dayjsTimezone])

  const handleResetPassword = () => {
    navigate(Routes.PASSWORD_RESET_SEND_CODE, { state: { email: viewer.email } })
  }

  if (viewerResult.loading) {
    return <Spin />
  }

  if (!viewerResult.data?.viewer) {
    return <Text>Sorry, something went wrong.</Text>
  }

  const viewer = viewerResult.data?.viewer
  const lastName = viewer.lastName ?? ''
  const firstName = viewer.firstName ?? ''

  localStorageSetItem(LocalStorageKeys.CodeEmail, viewer.email)
  localStorageSetItem(LocalStorageKeys.CodePhone, viewer.phone)

  const handleSave = async () => {
    const firstName = form.getFieldValue('firstName')
    const lastName = form.getFieldValue('lastName')
    const email = form.getFieldValue('email')
    const phone = form.getFieldValue('phone')
    const companyName = form.getFieldValue('companyName')
    const timezone = form.getFieldValue('timezone')
    const timezoneType = form.getFieldValue('timezoneType')
    const foxtraxAttribution = form.getFieldValue('foxtraxAttribution')
    const facebookAttribution = form.getFieldValue('facebookAttribution')
    const organizationId = viewer.organizationId
    const updatedTimezone = timezoneType === 'static' ? timezone : null

    await Promise.all([
      updateUserMutation({ variables: { id: viewer.id, user: { email, phone, firstName, lastName, timezone: updatedTimezone, foxtraxAttribution, facebookAttribution } } }),
      updateOrgMutation({ variables: { id: organizationId ?? '', input: { name: companyName } } }),
    ])

    if (facebookAttribution !== attributionSetting.facebookAttribution || foxtraxAttribution !== attributionSetting.foxtraxAttribution) {
      await setAttributionSetting({ facebookAttribution, foxtraxAttribution })
    }
  }

  return (
    <Form
      onFinishFailed={(errorInfo) => {
        setPhoneInputError(!!errorInfo.errorFields.find((e) => e.name[0] == 'phone'))
      }}
      validateTrigger={false}
      name="nest-messages"
      form={form}
      layout="vertical"
      initialValues={{
        id: viewer.id,
        firstName: firstName,
        lastName: lastName,
        companyName: viewer.organization?.name,
        email: viewer.email,
        phone: viewer.phone,
        countryCode: countryCode,
        timezone: viewer.timezone,
        timezoneType: viewer.timezone ? 'static' : 'dynamic',
        foxtraxAttribution: viewer.foxtraxAttribution,
        facebookAttribution: viewer.facebookAttribution,
      }}
      onFinish={handleSave}
      style={{ width: '100%' }}
    >
      <Col style={{ width: '100%' }}>
        <TitleText>Profile Settings</TitleText>
        <br />
        <br />
        <Row gutter={31.62}>
          <Col style={{ width: '50%' }}>
            <FieldLabelText>First Name</FieldLabelText>
            <Form.Item
              name="firstName"
              rules={[
                {
                  min: 2,
                  message: 'First name should be at least 2 characters',
                },
              ]}
            >
              <InputTextField />
            </Form.Item>
          </Col>
          <Col style={{ width: '50%' }}>
            <FieldLabelText>Last Name</FieldLabelText>
            <Form.Item
              name="lastName"
              rules={[
                {
                  min: 2,
                  message: 'Last name should be at least 2 characters',
                },
              ]}
            >
              <InputTextField />
            </Form.Item>
          </Col>
        </Row>
        <Col style={{ width: '100%' }}>
          <FieldLabelText>Company Name</FieldLabelText>
          <Form.Item
            name="companyName"
            rules={[
              {
                min: 2,
                message: 'Company name should be at least 2 characters',
              },
            ]}
          >
            <InputTextField />
          </Form.Item>
        </Col>
        <Col>
          <FieldLabelText>Email Address</FieldLabelText>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not a valid Email!',
              },
            ]}
          >
            <InputTextField />
          </Form.Item>
        </Col>
        <Col>
          <FieldLabelText>Phone No.</FieldLabelText>
          <Form.Item
            name="phone"
            rules={[
              {
                pattern: /^\+(?:[0-9]●?){6,14}[0-9]$/,
                message: 'Phone number must be a valid number.',
              },
            ]}
          >
            <PhoneInput
              border={phoneInputError ? colorError : colorBorder}
              defaultCountry="us"
              value={`+1 `}
              onChange={(_e, country) => {
                setCountryCode(country.country.dialCode)
              }}
              hideDropdown={true}
              disableDialCodeAndPrefix={true}
              showDisabledDialCodeAndPrefix={true}
            />
          </Form.Item>
        </Col>
        <Row gutter={32}>
          <Col xs={24} lg={12}>
            <FieldLabelText>FoxTrax Attribution</FieldLabelText>
            <Form.Item name="foxtraxAttribution">
              <AttributionSelectField size="large" style={{ width: '100%' }}>
                <Option value={FoxTraxAttribution.OneDayLastClick}>1 day last click</Option>
                <Option value={FoxTraxAttribution.SevenDayLastClick}>7 day last click</Option>
                <Option value={FoxTraxAttribution.OneDayFirstClick}>1 day first click</Option>
                <Option value={FoxTraxAttribution.SevenDayFirstClick}>7 day first click</Option>
              </AttributionSelectField>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <FieldLabelText>Facebook Attribution</FieldLabelText>
            <Form.Item name="facebookAttribution">
              <AttributionSelectField size="large" style={{ width: '100%' }}>
                <Option value={FacebookAttribution.OneDayLastClick}>1 day last click</Option>
                <Option value={FacebookAttribution.SevenDayLastClick}>7 day last click</Option>
                <Option value={FacebookAttribution.OneDayFirstClick}>1 day first click</Option>
                <Option value={FacebookAttribution.SevenDayFirstClick}>7 day first click</Option>
                <Option value={FacebookAttribution.SevenDayClickOrOneDayView}>7 day click or 1 day view</Option>
              </AttributionSelectField>
            </Form.Item>
          </Col>
        </Row>
        <Col style={{ width: '100%' }}>
          <TimezoneSelect timezone={viewer.timezone} />
        </Col>
        <Button block type="default" onClick={handleResetPassword}>
          <UnderlinedText>Reset Password</UnderlinedText>
        </Button>
        <br />
        <br />
        <Button disabled={updateUserLoading} block type="primary" htmlType="submit">
          {updateUserLoading ? <Spin /> : 'Save'}
        </Button>
      </Col>
    </Form>
  )
}

import { Checkbox, Form, Row, Typography, message, theme } from 'antd'
import { Role, useCreateUserMutation } from '../../__generated__/graphql'
import { useNavigate } from 'react-router-dom'
import { Card } from '../StyledComponents/Card'
import { Spin } from '../StyledComponents/Spin'
import { InputPassword, Input } from '../StyledComponents/Input'
import { FormItem } from '../StyledComponents/FormItem'
import { Button } from '../StyledComponents/Button'
import styled from 'styled-components'
import { breakpoints } from '../../theme/breakpoints'
import { Routes } from '../../Routes'
import { PhoneInput } from '../StyledComponents/PhoneInput'
import { useState } from 'react'
import { client } from '../../graphql/client'
import { TimezoneSelect } from '../TimezoneSelect/TimezoneSelect'

const NameFormItem = styled(FormItem)`
  display: inline-block
  width: calc(50% - 8px)
  @media (max-width: ${breakpoints.sm}) {
    display: block;
    width: 100%;
    margin-left: 0 !important
  }
`
interface RegisterFormValues {
  email: string
  password: string
  phone: string
  timezone: string
  firstName: string
  lastName: string
  timezoneType: 'static' | 'dynamic'
}

export const RegisterForm = () => {
  const {
    token: { colorBorder, colorError },
  } = theme.useToken()
  const [phoneInputError, setPhoneInputError] = useState(false)
  const [countryCode, setCountryCode] = useState('1')
  const navigate = useNavigate()
  const [createUserMutation, { loading }] = useCreateUserMutation({
    onCompleted: async (data) => {
      if (data.createUser.__typename === 'User') {
        await client.resetStore()
        navigate(Routes.SUBSCRIPTION)
      } else if (data.createUser.__typename === 'UserAlreadyExists') {
        message.error(data.createUser.error)
      } else {
        message.error('Registration failed')
      }
    },
    onError: () => {
      message.error('An unknown erorr occured')
    },
  })

  const handleSubmit = async ({ email, password, firstName, lastName, phone, timezone, timezoneType }: RegisterFormValues) => {
    try {
      await createUserMutation({
        variables: {
          input: {
            shouldCreateStoreAndOrganization: true,
            email,
            firstName,
            lastName,
            password,
            phone,
            countryCode: countryCode,
            timezone: timezoneType === 'static' ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
            role: Role.Owner,
          },
        },
      })
    } catch (error) {
      message.error('An unknown erorr occured')
    }
  }

  return (
    <>
      <Card>
        <Typography.Title style={{ textAlign: 'center' }} level={2}>
          Create Your Account
        </Typography.Title>
        <Form
          onFinishFailed={(errorInfo) => {
            setPhoneInputError(!!errorInfo.errorFields.find((e) => e.name[0] == 'phone'))
          }}
          validateTrigger={false}
          name="register-form"
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <NameFormItem name="firstName" label="First Name" rules={[{ required: true, message: 'Please input your First Name.' }]}>
            <Input size="large" placeholder="First Name" />
          </NameFormItem>
          <NameFormItem name="lastName" label="Last Name" rules={[{ required: true, message: 'Please input your Last Name.' }]}>
            <Input size="large" placeholder="Last Name" />
          </NameFormItem>
          <FormItem
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </FormItem>
          <FormItem
            label="Phone No"
            name="phone"
            initialValue={''}
            rules={[
              { required: true, message: 'Please input your phone number!' },
              {
                pattern: /^\+(?:[0-9]●?){6,14}[0-9]$/,
                message: 'Phone number must be a valid number.',
              },
            ]}
          >
            <PhoneInput
              border={phoneInputError ? colorError : colorBorder}
              defaultCountry="us"
              value={`+1 `}
              onChange={(_e, country) => {
                setCountryCode(country.country.dialCode)
              }}
              disableDialCodeAndPrefix={true}
              showDisabledDialCodeAndPrefix={true}
            />
          </FormItem>
          <FormItem
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              {
                min: 8,
                message: 'Password must be at least 8 characters',
              },
            ]}
          >
            <InputPassword size="large" placeholder="Password" />
          </FormItem>
          <TimezoneSelect />
          <FormItem>
            <Row justify={'space-between'} align={'middle'}>
              <FormItem
                name="remember"
                initialValue={true}
                valuePropName="checked"
                rules={[
                  {
                    validator: async (_, checked) => {
                      if (!checked) {
                        return Promise.reject(new Error('You must accept the terms and privacy policy.'))
                      }
                    },
                  },
                ]}
              >
                <Checkbox>
                  <Typography.Text
                    style={{
                      fontSize: '14px',
                      lineHeight: '21px',
                      fontWeight: '400',
                    }}
                  >
                    By signing up, you confirm that you've read and accepted our{' '}
                    <span
                      style={{ textDecoration: 'underline' }}
                      onClick={(e) => {
                        e.preventDefault()
                        window.open(Routes.TERMS_OF_SERVICE, '_blank')
                      }}
                    >
                      Terms of Service
                    </span>
                    {' and '}
                    <span
                      style={{ textDecoration: 'underline' }}
                      onClick={(e) => {
                        e.preventDefault()
                        window.open(Routes.PRIVACY_POLICY, '_blank')
                      }}
                    >
                      Privacy Policy
                    </span>
                  </Typography.Text>
                </Checkbox>
              </FormItem>
            </Row>
          </FormItem>
          <FormItem>
            <Button disabled={loading} block type="primary" htmlType="submit">
              {loading ? <Spin /> : 'Register'}
            </Button>
          </FormItem>

          <FormItem>
            Already have an account?
            <Typography.Link href="/login"> Login Here</Typography.Link>
          </FormItem>
        </Form>
      </Card>
    </>
  )
}

import { Tag } from 'antd'
import { ColumnGroupType, ColumnType } from 'antd/es/table/interface'
import { TableRow } from '../AttributionTable'
import { RowKey } from '../../machines/dashboardMachine/dashboardMachine'

export interface CustomColumnType extends ColumnType<TableRow> {
  totalsKey?: string
  shouldHideTotal?: boolean
}

export const getColumns = (showDailyBudget = true, onNameClick?: (key: RowKey) => void): (CustomColumnType)[] => {
  const isProduction = import.meta.env.VITE_USER_NODE_ENV === 'production'
  return [
    {
    ellipsis: true,
    width: 240,
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    align: 'left',
    render: (text: string, record: TableRow) => {
      return (
        <span onClick={() => onNameClick && onNameClick(record.key)}>
          <a>{text}</a> <span style={{ display: 'block' }}>{record.id}</span>
        </span>
      )
    },
  },
  {
    ellipsis: true,
    width: 140,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (text: string) => {
      let color = ''
      if (text === 'ACTIVE') {
        color = 'green'
      } else if (text === 'PAUSED') {
        color = 'grey'
      }
      return <Tag color={color}>{text}</Tag>
    },
  },
  ...(showDailyBudget
    ? [
        {
          ellipsis: true,
          width: 140,
          title: 'Budget',
          dataIndex: 'budget',
          key: 'dailyBudget',
          shouldHideTotal: true,
          align: 'center' as const,
          render: (text: number) => {
            return text !== null && text !== undefined ? `$${text.toFixed(2)} (Daily)` : '-'
          },
        },
      ]
    : []),
  {
    ellipsis: true,
    width: 140,
    title: 'Spend',
    dataIndex: 'spend',
    key: 'spend',
    totalsKey: 'spend',
    align: 'center',
    render: (text: number) => {
      return text !== null && text !== undefined ? `$${text.toFixed(2)}` : '-'
    },
  },
  {
    ellipsis: true,
    width: 165,
    title: isProduction ? 'Orders' : 'Orders (FoxTrax)',
    dataIndex: 'orders',
    key: 'orders',
    totalsKey: 'attributedOrders',
    align: 'center',
    render: (text: number) => {
      return text !== null && text !== undefined ? text.toLocaleString('en-US') : '-'
    },
  },
  ...(!isProduction
    ? [
        {
          ellipsis: true,
          width: 165,
          title: 'Orders (Facebook)',
          dataIndex: 'sourceOrders',
          key: 'sourceOrders',
          totalsKey: 'facebookOrders',
          align: 'center' as const,
          render: (text: number) => {
            return text !== null && text !== undefined ? text.toLocaleString('en-US') : '-'
          },
        },
      ]
    : []),
  {
    ellipsis: true,
    width: 170,
    title: isProduction ? 'Revenue' : 'Revenue (FoxTrax)',
    dataIndex: 'ordersValue',
    key: 'ordersValue',
    totalsKey: 'attributedRevenue',
    align: 'center',
    render: (text: number) => {
      return <div style={{ paddingRight: 8 }}>{text !== null && text !== undefined ? `$${text.toFixed(2)}` : '-'}</div>
    },
  },
  ...(!isProduction
    ? [
        {
          ellipsis: true,
          width: 170,
          title: 'Revenue (Facebook)',
          dataIndex: 'sourceOrdersValue',
          key: 'sourceOrdersValue',
          totalsKey: 'facebookRevenue',
          align: 'center' as const,
          render: (text: number) => {
            return <div style={{ paddingRight: 8 }}>{text !== null && text !== undefined ? `$${text.toFixed(2)}` : '-'}</div>
          },
        },
      ]
    : []),
  ]
}

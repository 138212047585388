import { Card, Typography, theme, Divider, Tooltip } from 'antd'
import styled from 'styled-components'
import { useContext, useRef, useEffect, useState } from 'react'
import { cardsMetricsSelector } from '../../utils/dashboard-util'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { useSelector } from '@xstate/react/lib/useSelector'
import { InfoCircleOutlined } from '@ant-design/icons'
import shopify from '../../assets/shopify.svg'
import facebook from '../../assets/fb.png'

const { Text, Title } = Typography

// Types
interface MetricData {
  label: string
  value: number
  isRevenue?: boolean
  organicTooltip?: string
  organicTooltipTitle?: string
}

export interface DashboardData {
  dailyBudget?: number | null
  orders?: number | null
  revenue?: number | null
  spend?: number | null
  attributedOrders?: number | null
  attributedRevenue?: number | null
  facebookOrders?: number | null
  facebookRevenue?: number | null
  organicOrders?: number | null
  organicRevenue?: number | null
}

// Styled Components
const MetricCard = styled(Card)`
  border-radius: 20px;
  min-width: 320px;
  flex: 1;
  transition: transform 0.2s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  .ant-card-body {
    padding: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 768px) {
    min-width: unset;
    width: 100%;
    
    .ant-card-body {
      padding: 16px;
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 8px;
  cursor: grab;
  user-select: none;
  
  &:active {
    cursor: grabbing;
  }
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 16px;
    gap: 12px;
    overflow-x: visible;
    cursor: default;
  }
`;

const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 6px;
  }
`;

const MetricsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 8px;
  }
`;

const MetricRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  text-align: left;
`;

const MetricValue = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin: 0;
  transition: color 0.2s ease;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const MetricLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgba(255, 255, 255, 0.55);
  font-size: 14px;
  margin: 0;
  transition: color 0.2s ease;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const CardTitle = styled(Text)`
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 6px;

  @media (max-width: 768px) {
    font-size: 17px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const InfoIcon = styled(InfoCircleOutlined)`
  color: rgba(255, 255, 255, 0.65);
  font-size: 16px;
  cursor: help;
  transition: color 0.2s ease;
  margin-right: 8px;

  &:hover {
    color: rgba(255, 255, 255, 0.85);
  }
`;

const TotalValue = styled(Title)`
  color: white !important;
  font-size: 38px !important;
  font-weight: 600 !important;
  margin: 8px 0 12px 0 !important;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: -0.02em;

  @media (max-width: 768px) {
    font-size: 42px !important;
    margin: 8px 0 16px 0 !important;
  }
`;

const StyledDivider = styled(Divider)`
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0 24px !important;
  height: 20px !important;
  width: 1px !important;

  @media (max-width: 768px) {
    margin: 0 32px !important;
  }
`;

const ValueContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 100%;
`;

const CardIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  border-radius: 50%;
`;

// Components
const Metric: React.FC<MetricData> = ({ label, value, isRevenue, organicTooltip, organicTooltipTitle }) => (
  <MetricRow>
    <MetricLabel>
      {label}
      {organicTooltip && organicTooltipTitle && (
        <Tooltip color='white' title={<><Text style={{ color: '#000000'}} strong>{organicTooltipTitle}</Text><br/><Text style={{ color: '#000000', fontStyle: 'italic', fontSize: '12px' }}>{organicTooltip}</Text></>} placement="top" trigger="hover">
          <InfoIcon />
        </Tooltip>
      )}
    </MetricLabel>
    <MetricValue>
      {isRevenue ? `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : value.toLocaleString()}
    </MetricValue>
  </MetricRow>
);

const MetricCardComponent: React.FC<{
  title: string
  totalValue: number | string
  metrics?: MetricData[]
  isRevenue?: boolean
  tooltip?: string
  tooltipTitle?: string
  icon?: string
}> = ({ title, totalValue, metrics, isRevenue, tooltip, tooltipTitle, icon }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken()

  return (
    <MetricCard style={{ background: colorPrimary }}>
      <MetricContainer>
        <TitleContainer>
          <CardTitle style={{ color: '#FFFFFF', textShadow: '0 2px 4px rgba(0,0,0,0.2)' }}>
            {title}
            {icon && <CardIcon src={icon} alt={title} />}
          </CardTitle>
          {tooltip && tooltipTitle && (
            <Tooltip color='white' title={<><Text style={{ color: '#000000'}} strong>{tooltipTitle}</Text><br/><Text style={{ color: '#000000', fontStyle: 'italic', fontSize: '12px' }}>{tooltip}</Text></>} placement="top" trigger="hover">
              <InfoCircleOutlined style={{ color: '#FFFFFF', marginLeft: '8px', fontSize: '16px' }} />
            </Tooltip>
          )}
        </TitleContainer>
        <TotalValue style={{ marginBottom: metrics ? undefined : 0 }}>
          {isRevenue ? `$${totalValue}` : totalValue}
        </TotalValue>
        {metrics && (
          <MetricsRow>
            <ValueContainer>
              <Metric {...metrics[0]} />
              <StyledDivider type="vertical" />
              <Metric {...metrics[1]} />
            </ValueContainer>
          </MetricsRow>
        )}
      </MetricContainer>
    </MetricCard>
  )
}

// Utility functions
const formatCurrency = (value: number) => {
  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

// Main Component
export const MetricsContainer = () => {
  const globalServices = useContext(GlobalStateContext)
  const data = useSelector(globalServices.dashboardService, cardsMetricsSelector) as DashboardData

  // Drag scroll functionality
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true)
    setStartX(e.pageX - (containerRef.current?.offsetLeft ?? 0))
    setScrollLeft(containerRef.current?.scrollLeft ?? 0)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return
    
    e.preventDefault()
    const x = e.pageX - (containerRef.current?.offsetLeft ?? 0)
    const walk = (x - startX) * 1.5
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft - walk
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    return () => document.removeEventListener('mouseup', handleMouseUp)
  }, [])

  return (
    <CardsContainer
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <MetricCardComponent
        title="Orders"
        totalValue={(data?.orders ?? 0).toLocaleString()}
        metrics={[
          { label: "Facebook", value: data?.attributedOrders ?? 0 },
          { 
            label: "Organic", 
            value: data?.organicOrders ?? 0,
            organicTooltip: "Orders that came from sources other than your Facebook ads. Foxtrax only tracks orders after you connected your pixel to your store.",
            organicTooltipTitle: "What are organic orders?"
          }
        ]}
        tooltip="Facebook often takes credit for purchases that weren't directly from your ads. Foxtrax only tracks orders that come directly from ad clicks, giving you more accurate results."
        tooltipTitle="Why does Facebook show more orders than Foxtrax?"
        icon={shopify}
      />
      <MetricCardComponent
        title="Revenue"
        totalValue={formatCurrency(data?.revenue ?? 0)}
        metrics={[
          { label: "Facebook", value: data?.attributedRevenue ?? 0, isRevenue: true },
          { 
            label: "Organic", 
            value: data?.organicRevenue ?? 0, 
            isRevenue: true,
            organicTooltip: "Revenue from orders that came from sources other than your Facebook ads",
            organicTooltipTitle: "What is organic revenue?"
          }
        ]}
        isRevenue
        icon={shopify}
      />
      <MetricCardComponent
        title="Spend"
        totalValue={formatCurrency(data?.spend ?? 0)}
        isRevenue
        icon={facebook}
      />
    </CardsContainer>
  )
}

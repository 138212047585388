import { Card, Typography, theme, Space } from 'antd'
import styled from 'styled-components'
import { ProfileAction } from '../../components/HeaderActions/Profile'
import { useContext, useState, useEffect } from 'react'
import { JwtContext } from '../../contexts/jwtContext'
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout'
import { 
  useShopifyInstallUrlQuery, 
  useGetUserStatusQuery, 
  useGenerateFacebookLinkMutation,
  useDisconnectAdPlatformMutation,
  Platform,
  AdsByAdManagerAccountIdsDocument,
  AdsetsByAdManagerAccountIdsDocument,
  ProgressDocument
} from '../../__generated__/graphql'
import { CheckCircleFilled } from '@ant-design/icons'
import { Button } from '../../components/StyledComponents'
import facebookIconWhite from '../../assets/fb-white.svg'
import shopifyIconWhite from '../../assets/shopify-white.svg'
import shopifyIcon from '../../assets/shopify.svg'
import discordIconWhite from '../../assets/discord-white.svg'
import { client } from '../../graphql/client'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { isConnectedToFacebook } from '../../Router'
import { useQuery, useSubscription } from '@apollo/client'

const { Title, Text } = Typography

const SectionCard = styled(Card)`
  margin-bottom: 24px;
  border-radius: 8px;
`

const IntegrationButton = styled(Button)`
  height: 48px !important;
  padding: 0 24px !important;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (min-width: 768px) {
    max-width: 400px;
  }
`

const FacebookButton = styled(IntegrationButton)`
  &.ant-btn-default {
    background-color: #1877f2;
    color: white;
    &:hover {
      background-color: #2884f3 !important;
      color: white !important;
    }
  }

  &.ant-btn-dangerous {
    background-color: rgba(255, 77, 79, 0.1);
    border-color: #ff4d4f;
    color: #ff4d4f;
    &:hover {
      background-color: rgba(255, 77, 79, 0.2) !important;
      border-color: #ff4d4f !important;
      color: #ff4d4f !important;
    }
  }
`

const IntegrationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`

const MobileTextContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    text-align: left;
  }
`

const ButtonContainer = styled(Space)`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .ant-space-item {
    width: 100%;  // Make Space items take full width
  }
  
  @media (min-width: 768px) {
    flex-direction: row;
    .ant-space-item {
      width: auto;  // Reset width on desktop
    }
  }
`

const DiscordButton = styled(Button)`
  height: 48px !important;
  padding: 0 24px !important;
  border-radius: 8px;
  font-weight: 600;
  background-color: #5865F2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  &:hover {
    background-color: #7983f2 !important;
    color: white !important;
  }
  
  @media (min-width: 768px) {
    max-width: 400px;
  }
`

const MobileAppButton = styled(Button)`
  height: 48px !important;
  padding: 0 24px !important;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (min-width: 768px) {
    width: 400px;
  }
`

enum FacebookConnectionState {
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  DOWNLOADING = 'DOWNLOADING',
  DOWNLOADED = 'DOWNLOADED',
  DISCONNECTING = 'DISCONNECTING',
  DISCONNECTED = 'DISCONNECTED'
}

export const QuickStart = () => {
  const decodedToken = useContext(JwtContext)
  const { data: shopifyData, loading: loadingShopifyUrl } = useShopifyInstallUrlQuery()
  const [generateFacebookLink] = useGenerateFacebookLinkMutation()
  const [disconnectAdPlatform] = useDisconnectAdPlatformMutation()
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService
  const resetToDefaultContext = () => send({ type: 'RESET_TO_DEFAULT_EVENT' })
  
  const { data: userStatusData } = useGetUserStatusQuery({
    variables: { id: decodedToken?.stores[0] ?? '' },
    fetchPolicy: 'network-only',
  })
  
  const isShopifyConnected = userStatusData?.userStatusByStoreId.hasInstalledShopifyApp
  const isFacebookConnected = userStatusData?.userStatusByStoreId.isConnectedToFacebook

  const {
    token: { colorSuccess, colorTextTertiary },
  } = theme.useToken()

  const [hasOpenedFacebook, setHasOpenedFacebook] = useState(false)
  const [pollTimeout, setPollTimeout] = useState<NodeJS.Timeout | null>(null)
  
  const { loading: adsLoading } = useQuery(AdsByAdManagerAccountIdsDocument, {
    variables: { ids: [] },
    skip: !isFacebookConnected,
    pollInterval: 5000
  })

  const { loading: adsetsLoading } = useQuery(AdsetsByAdManagerAccountIdsDocument, {
    variables: { ids: [] },
    skip: !isFacebookConnected,
    pollInterval: 5000
  })

  useSubscription(ProgressDocument, {
    variables: {
      progressId: decodedToken?.stores[0] ?? '',
    },
    onData: ({ data }) => {
      if (data.data?.progress) {
        client.refetchQueries({
          include: [
            'GetUserStatus',
            'AdsByAdManagerAccountIds',
            'AdsetsByAdManagerAccountIds',
            'AdManagerAccountsByStoreId',
            'AdPlatformAccountsByStoreId',
            'CampaignsByAdManagerAccountIds'
          ]
        })
      }
    }
  })

  const getConnectionState = (): FacebookConnectionState => {
    if (!isFacebookConnected) {
      return FacebookConnectionState.DISCONNECTED
    }

    if (adsLoading || adsetsLoading) {
      return FacebookConnectionState.DOWNLOADING
    }

    return FacebookConnectionState.CONNECTED
  }

  useEffect(() => {
    if (decodedToken?.stores[0] && (hasOpenedFacebook || !isFacebookConnected)) {
      if (hasOpenedFacebook) {
        isConnectedToFacebook(false)
      }
      generateFacebookLink({
        variables: {
          id: decodedToken.stores[0],
        },
      })
      const interval = connectToFacebookAdPlatform()
      
      const timeout = setTimeout(() => {
        if (interval) {
          clearInterval(interval)
          isConnectedToFacebook(false)
          setHasOpenedFacebook(false)
        }
      }, 120000)
      
      setPollTimeout(timeout)

      return () => {
        clearInterval(interval)
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    }
  }, [decodedToken?.stores, hasOpenedFacebook])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && hasOpenedFacebook) {
        client.refetchQueries({
          include: [
            'GetUserStatus',
            'AdsByAdManagerAccountIds',
            'AdsetsByAdManagerAccountIds'
          ]
        })
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [hasOpenedFacebook])

  const handleFacebookConnect = async () => {
    if (!isFacebookConnected && decodedToken?.stores[0]) {
      isConnectedToFacebook(false)
      
      if (pollTimeout) {
        clearTimeout(pollTimeout)
      }
      
      const response = await generateFacebookLink({
        variables: {
          id: decodedToken.stores[0],
        },
      })
      
      if (response.data?.generateFacebookLinkByStoreId.oauthLink) {
        setHasOpenedFacebook(true)
        window.open(response.data.generateFacebookLinkByStoreId.oauthLink, '_blank')
        connectToFacebookAdPlatform()
      }
    }
  }

  const connectToFacebookAdPlatform = () => {
    const interval = setInterval(async () => {
      const generateFacebookLinkResponse = await generateFacebookLink({
        variables: {
          id: decodedToken?.stores[0] ?? '',
        },
      })
      
      if (generateFacebookLinkResponse.data?.generateFacebookLinkByStoreId.connected) {
        isConnectedToFacebook(true)
        setHasOpenedFacebook(false)
        
        await client.resetStore()
        
        await client.refetchQueries({
          include: [
            'GetUserStatus',
            'AdsByAdManagerAccountIds',
            'AdsetsByAdManagerAccountIds',
            'AdManagerAccountsByStoreId',
            'AdPlatformAccountsByStoreId',
            'CampaignsByAdManagerAccountIds'
          ]
        })

        if (pollTimeout) {
          clearTimeout(pollTimeout)
        }
        clearInterval(interval)
      }
    }, 5000)
    return interval
  }

  const handleFacebookDisconnect = async () => {
    isConnectedToFacebook(false)
    setHasOpenedFacebook(false)
    
    const response = await disconnectAdPlatform({
      variables: {
        id: decodedToken?.stores[0] ?? '',
        platform: Platform.Facebook,
      },
    })

    if (response.data?.disconnectAdPlatformByStoreId.__typename === 'DisconnectAdPlatform' &&
        response.data.disconnectAdPlatformByStoreId.disconnected) {
      await client.clearStore()
      resetToDefaultContext()
      await client.refetchQueries({
        include: ['GetUserStatus'],
      })
      const generateFacebookLinkResponse = await generateFacebookLink({
        variables: {
          id: decodedToken?.stores[0] ?? '',
        },
      })
      connectToFacebookAdPlatform()
    }
  }

  const renderFacebookButton = () => {
    const connectionState = getConnectionState()
    
    const showDisconnectButton = [
      FacebookConnectionState.CONNECTED,
      FacebookConnectionState.DOWNLOADING,
      FacebookConnectionState.DOWNLOADED
    ].includes(connectionState)

    const showLoader = connectionState === FacebookConnectionState.DOWNLOADING

    if (showDisconnectButton) {
      return (
        <FacebookButton
          onClick={handleFacebookDisconnect}
          loading={showLoader}
          danger
          block
        >
          {connectionState === FacebookConnectionState.DISCONNECTING 
            ? 'Disconnecting...' 
            : 'Disconnect'}
        </FacebookButton>
      )
    }

    return (
      <FacebookButton
        icon={<img src={facebookIconWhite} alt="Facebook" style={{ height: 20, marginRight: 8 }} />}
        onClick={handleFacebookConnect}
        loading={showLoader}
        type="default"
        block
      >
        {connectionState === FacebookConnectionState.DOWNLOADING 
          ? 'Connecting to Facebook...' 
          : 'Connect Facebook'}
      </FacebookButton>
    )
  }

  return (
    <ResponsiveSiderLayout title="Setup Your Account" primaryPageKey="quick-start" actions={<ProfileAction />}>
      <div style={{ padding: '24px', maxWidth: '1200px', margin: '0 auto' }}>
        <SectionCard>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <IntegrationContainer>
              <Title level={4}>1. Install FoxTrax Shopify App</Title>
              <IntegrationButton
                icon={<img src={isShopifyConnected ? shopifyIcon : shopifyIconWhite} alt="Shopify" style={{ height: 20, marginRight: 8 }} />}
                loading={loadingShopifyUrl} 
                disabled={isShopifyConnected} 
                onClick={() => !isShopifyConnected && shopifyData?.shopifyInstallUrl && window.open(shopifyData.shopifyInstallUrl, '_blank')} 
                type="primary" 
                block
              >
                {isShopifyConnected ? (
                  <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Text style={{ color: colorTextTertiary }}>Connected to Shopify</Text>
                    <CheckCircleFilled style={{ fontSize: '18px', color: colorSuccess }} />
                  </Space>
                ) : (
                  'Connect Shopify'
                )}
              </IntegrationButton>
            </IntegrationContainer>

            <IntegrationContainer>
              <Title level={4}>2. Connect to Facebook</Title>
              {renderFacebookButton()}
            </IntegrationContainer>

            {import.meta.env.VITE_MOBILE_APP_AVAILABLE === 'true' && (
              <IntegrationContainer>
                <Title level={4}>3. Download Mobile App</Title>
                <MobileTextContainer>
                  <Text>
                    Download our mobile app to track your metrics on the go.
                  </Text>
                </MobileTextContainer>
                <ButtonContainer>
                  <MobileAppButton 
                    type="primary"
                    block 
                    onClick={() => window.open(import.meta.env.VITE_MOBILE_IOS_URL, '_blank')}
                  >
                    Download for iOS
                  </MobileAppButton>
                  <MobileAppButton 
                    type="primary"
                    block 
                    onClick={() => window.open(import.meta.env.VITE_MOBILE_ANDROID_URL, '_blank')}
                  >
                    Download for Android
                  </MobileAppButton>
                </ButtonContainer>
              </IntegrationContainer>
            )}

            <IntegrationContainer>
              <Title level={4}>{import.meta.env.VITE_MOBILE_APP_AVAILABLE === 'true' ? '4' : '3'}. Join Support Group</Title>
              <DiscordButton
                icon={<img src={discordIconWhite} alt="Discord" style={{ height: 24, marginRight: 8 }} />}
                onClick={() => window.open('https://discord.gg/MBu7KeXD', '_blank')}
                block
              >
              </DiscordButton>
            </IntegrationContainer>
          </Space>
        </SectionCard>
      </div>
    </ResponsiveSiderLayout>
  )
}

export default QuickStart

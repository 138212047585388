import { Card } from 'antd'
import { SubscriptionCarosel } from '../QuickStart/Subscription/SubscriptionCarosel'
import { Routes } from '../../Routes'
import styled from 'styled-components'
import { ProfileAction } from '../../components/HeaderActions/Profile'
import logo from '../../assets/logo.svg'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f5f5f5;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const StyledCard = styled(Card)`
  width: 100%;
  height: 100vh;
`

export const SubscriptionPage = () => {
  const handleSubscriptionSelected = (link: string | undefined) => {
    if (link) {
      window.location.href = link
    }
  }
  return (
    <Container>
      <StyledCard>
        <Header>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <img src={logo} alt="FoxTrax" style={{ height: 40 }} />
            <ProfileAction hideProfile={true} />
          </div>
        </Header>
        <SubscriptionCarosel onSubscriptionSelected={handleSubscriptionSelected} successUrl={Routes.CONNECT_ACCOUNTS} failUrl={Routes.SUBSCRIPTION} />
      </StyledCard>
    </Container>
  )
}

export default SubscriptionPage

import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { JwtContext } from '../../contexts/jwtContext'
import { Routes } from '../../Routes'
import { useSubscriptionPlanByStoreIdQuery, SubscriptionPlanStatus } from '../../__generated__/graphql'

interface SubscriptionGuardProps {
  children: React.ReactNode
}

export const SubscriptionGuard = ({ children }: SubscriptionGuardProps) => {
  const location = useLocation()
  const decodedToken = useContext(JwtContext)
  const storeId = decodedToken?.stores[0]

  // List of routes that don't require subscription
  const unprotectedRoutes = [
    Routes.SUBSCRIPTION,
    Routes.REGISTER,
    Routes.LOGIN,
    Routes.TERMS_OF_SERVICE,
    Routes.PRIVACY_POLICY
  ]

  const { data, loading } = useSubscriptionPlanByStoreIdQuery({
    variables: {
      storeId: storeId || '',
    },
    skip: !storeId,
  })

  const isSubscribed = data?.subscriptionPlanByStoreId?.__typename === 'SubscriptionPlan' && 
    [SubscriptionPlanStatus.Active, SubscriptionPlanStatus.Trialing].includes(data.subscriptionPlanByStoreId.status)

  const currentRouteIsUnprotected = unprotectedRoutes.some(route => 
    location.pathname === route || location.pathname.startsWith(route)
  )

  if (loading) {
    return null // Or a loading spinner
  }

  if (!isSubscribed && !currentRouteIsUnprotected) {
    return <Navigate to={Routes.SUBSCRIPTION} state={{ from: location }} replace />
  }

  return <>{children}</>
}

export default SubscriptionGuard 